<template>
    <div
        class="card"
    >
        <div class="card-body">
            <div class="container">
                <el-form
                    ref="ruleFormRef"
                    :model="addUser"
                    label-position="top"
                    v-if="addUser"
                >
                    <div class="row">
                        <div class="col-sm-6">
                            <el-form-item
                                prop="name"
                            >
                                <label for="name" class="required">Name</label>
                                <el-input
                                    v-model="addUser.name"
                                    id="name"
                                    placeholder="Name"
                                    ref="name"
                                    disabled
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="email"
                            >
                                <label for="email" class="required">Username</label>
                                <el-input
                                    v-model="addUser.email"
                                    id="email"
                                    placeholder="email"
                                    ref="email"
                                    disabled
                                />
                            </el-form-item>
                        </div>
                        <div class="col-sm-6">
                            <el-form-item
                                prop="official_email"
                            >
                                <label for="official_email" class="required">Official Email</label>
                                <el-input
                                    v-model="addUser.official_email"
                                    id="official_email"
                                    placeholder="Official Email"
                                    ref="official_email"
                                    disabled
                                />
                            </el-form-item>
                        </div>
                       
                        <div class="col-sm-6">
                            <el-form-item
                                prop="role_id"
                            >
                                <label for="role_id" class="required">Role</label>
                                <el-select
                                    v-model="addUser.role_id"
                                    style="width: 100%;"
                                    ref="role_id"
                                    filterable
                                    disabled
                                >
                                    <el-option :value="0" label="Select Role" />
                                    <el-option v-for="role in roles" :key="role.value" :value="role.value" :label="role.label" />
                                </el-select>
                            </el-form-item>
                        </div>
                       
                    </div>
                    <div class="row">
                        <h3>Related Users:</h3>
                        <div class="card mt-5" v-if="switchUsersLoading">
                                Please wait while fetching the data....
                        </div>
                        <vc-tables 
                            v-else
                            is-sr
                            :headers="switch_users"
                            :data="lists"
                            :show-actions="false"
                            is-switch-action
                            :srs="srs"
                            @switchIds="switchIds"
                            :ids="ids"
                        />
                    </div>
                </el-form>
            </div>
        </div>
        <div class="card-footer">
            <div class="container">
                <div class="row text-center">
                    <div class="col-sm6">
                        <button
                            type="button"
                            class="btn btn-secondary me-2" 
                            @click="cancel"
                         >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="btn btn-info" 
                            @click="handleClick(ruleFormRef)"
                            :disabled="loading || lists.length == 0"
                         >
                            <span v-if="loading">
                                Please wait...
                            </span>
                            <span v-else>
                                &nbsp;&nbsp;Save&nbsp;&nbsp;
                            </span>
                        </button>
                    </div>                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, watch, computed, getCurrentInstance } from 'vue'
// import { addUser, setAddUser } from '@/store/composable/User.js'
import Validations from '@/core/services/etc/Validations'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { languages, insurance_types, assign_leads, is_all_deals, agent_types, yesNos, statuses } from '@/core/utils/User.ts'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { notif } from '@/store/stateless/store';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { switch_users } from '@/core/utils/Tables.ts'
import { ElLoading } from 'element-plus'

const ruleFormRef = ref()
const show = ref(false)
const showConfirm = ref(false)
const roles = ref([])
const tls = ref([])
const uws = ref([])
const fagents = ref([])
const calledTls = ref(false)
const calledUws = ref(false)
const calledAgents = ref(false)
const alreadyCalled = ref(false)
const loading = ref(false)
const isLoaded = ref(false)
const lists = ref([])

const route = useRoute()
const router = useRouter()
const store = useStore()

onMounted(() => {
    getRoles()
    getUser()
    
})

const addUser = computed(() => store.state.User.addUser)

const isShowOptions = computed(() => {
    return [2, 13, 9, 4].includes(addUser.value.role_id)
})

const cUws = computed(() => {
    if(!addUser.value.is_underwriter) return uws.value
    else return uws.value.filter(x => x.official_email == addUser.value.official_email)
})

watch(() => addUser.value, async(newUser) => {
        listing()
}, {deep: true})

async function getRoles() {
    const response = await useUpdateCustomerData({linkType: 'user-roles'})
    roles.value = response.data.data.roles
}


async function getUser() {
    if(route.params.userId) {
        setCurrentPageBreadcrumbs('Switch App', ['Users'])
        const response = await useUpdateCustomerData({linkType: 'user-detail', user_id: route.params.userId })
        store.commit('SET_USER', store.getters.getUserData(response.data.data.user))
        isLoaded.value = true
    }
}

const inst = getCurrentInstance()
const ids = ref([])
async function handleClick(formEl) {
    if(!formEl) return
    formEl.validate(async(valid, fields) => {
        loading.value = true

        if(valid) {

            const data = {
                id:addUser.value.id,
                switch_ids:ids.value,
                linkType: 'switch-user-link'
            }

            const response = await useUpdateCustomerData(data)
            if(response.status < 299) {
                notif.simple('User', 'success', 'Switch App setting saved successfully!')
                await cancel()
            }
            loading.value = false

        } else {
            const firstError = Object.keys(fields)[0]
            const toError = inst.refs[firstError]
            if(toError != undefined) {
                toError.focus()
            }
            loading.value = false
        }
    })
    
}



async function cancel() {
    await router.push({ name: 'user-list' })
}

const switchUsersLoading = ref(false)
async function listing() {
    ids.value = addUser.value.linked_users
    console.log('addUser.value',ids.value)
    const loading = ElLoading.service({
                // target: '.policies-list',
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
    switchUsersLoading.value = true

    const params = {
        linkType: 'roundrobin-listing',
        official_email : addUser.value.official_email,
        skip_user_id : addUser.value.id,
        // role_id:[2,9,13]
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const response = await useUpdateCustomerData(params)
    setTimeout(() => {
        lists.value = store.getters.getRoundRobinUserLists(response.data.data.users.data)
    }, 200);
            loading.close()
            // loading.value = false
    switchUsersLoading.value = false
}

async function switchIds(value){
    ids.value = value
    console.log(`Switch App Link Users`, ids.value);

}
</script>